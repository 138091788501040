import React from "react";
import "./Modal.scss";
import MiniLoading from "components/MiniLoading";

function Modal({ show, setShow, loading, title, children }) {
  return show ? (
    <div className="modal">
      <div className="h-modal modal_wrapper">
        {/* <!-- Modal content --> */}
        <div className="relative rounded-md shadow bg-white p-2">
          {loading && (
            <div className="modal_loading">
              <MiniLoading size={200}/>
            </div>
          )}
          {/* <!-- Modal header --> */}
          <div className="flex justify-between items-center p-3 lg:p-5">
            <h3 className="text-xl font-medium text-gray-900">{title}</h3>
            <button
              className="close_btn"
              onClick={() => {
                setShow(false);
              }}
              data-modal-toggle="large-modal"
            >
              <svg
                className="w-5 h-5"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clipRule="evenodd"
                ></path>
              </svg>
            </button>
          </div>
          <hr className="my-2" />
          {/* <!-- Modal body --> */}
          <div
            className="modal_body p-3 lg:p-5"
            //  style={{ maxHeight: "200px" }}
          >
            {children}
          </div>
        </div>
      </div>
    </div>
  ) : (
    <></>
  );
}

export default Modal;
