//============== React & Next ================
import React from "react";
import "./Button.scss";
//============== images & icons ==============
//============== in Components ===============
//============== ex Components ===============
//================= redux ====================
//============================================
export const ButtonTypes = {
  FILL: "FILL",
  OUTLINE: "OUTLINE",
};
function Button(props) {
  const { children, loading, type, ...otherProps } = props;
  return (
    <div className="Button_wrapper">
      <div className="Button_container">
        <button
          className={type === ButtonTypes.FILL ? "fill" : "outline"}
          {...otherProps}
          disabled={loading}
        >
          {loading ? (
            <div className="button_loading">
              <div className="line"></div>
              <div className="line"></div>
              <div className="line"></div>
            </div>
          ) : (
            children
          )}
        </button>
      </div>
    </div>
  );
}

export default Button;
