// ============= react ===================
import React, { useState, useEffect } from "react";
// ============= styles ===================
// ============= images & icons ===================
import { Boxes, PeopleFill, PersonFill } from "react-bootstrap-icons";
import avatar from "assets/images/avatar.jpg";
// ============= in components ===================
import SingleMenuItem from "./SingleMenuItem";
// ============= ex comonents ===================
import { Link, useLocation, useParams } from "react-router-dom";
// import MultiMenuItem from "./MultiMenuItem";
// =================== redux ====================

// const Sidebar = React.forwardRef((ref) => {
const Sidebar = ({sideBarRef}) => {
  const [activedMenu, setActivedMenu] = useState("");
  const [openingMenuId, setOpeningMenuId] = useState("");
  const route = useLocation();
  const params = useParams();
  // const toggleMenu = (id) => {
  //   if (openingMenuId === id) {
  //     setOpeningMenuId("");
  //   } else {
  //     setOpeningMenuId(id);
  //   }
  // };
  useEffect(() => {
    if (params.type) {
      setOpeningMenuId(
        route.pathname.replace("/", "").replace(`/${params.type}`, "")
      );
      setActivedMenu(params.type);
    } else {
      setActivedMenu(route.pathname.replace("/", ""));
    }
  }, [route]);
  return (
    <div className="left-sidebar text-sm">
      <div>
        <div ref={sideBarRef}>
          <div className="flex flex-col">
            <div className="logo h-16 flex items-center font-bold text-lg tracking-wider justify-center px-4">
              <Link to="/" className="set_row_middle mt-5">
                لوگـــــــــــــــــو
              </Link>
            </div>
            <div className="user-card">
              <div className="w-full p-4 pb-0">
                <div className="flex items-center justify-start">
                  <div className="flex-shrink-0 w-12">
                    <img
                      src={avatar}
                      alt="avatar"
                      className="shadow rounded-full h-12 w-12 border-2"
                    />
                  </div>
                  <div className="ltr:ml-2 rtl:mr-2 py-2">
                    <p className="text-base font-bold mb-1">مدیر وبسایت</p>
                    <Link
                      to="/profile"
                      className="flex items-center justify-start text-sm"
                    >
                      <PersonFill />
                      حساب کابری
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-col">
            <div className="uppercase font-bold text-xs tracking-wider p-4">
              بخش اصلی
            </div>
            <div className="flex flex-col">
              <ul className="list-none px-4">
                <SingleMenuItem
                  isActive={activedMenu === "dashboard"}
                  icon={<Boxes size="1.3rem" className="ml-3" />}
                  text={"داشبورد"}
                  href={"/dashboard"}
                />
              </ul>
            </div>
            <div className="uppercase font-bold text-xs tracking-wider p-4">
              دانـش‌ آموزان
            </div>
            <div className="flex flex-col">
              <ul className="list-none px-4">
                <SingleMenuItem
                  isActive={activedMenu === "students"}
                  icon={<PeopleFill size="1.3rem" className="ml-3" />}
                  text={"مدیریت دانـش‌ آموزان"}
                  href={"/students"}
                />
              </ul>
            </div>
            {/* <div className="uppercase font-bold text-xs tracking-wider p-4">
              سایر
            </div>
            <div className="flex flex-col">
              <ul className="list-none px-4">
                <MultiMenuItem
                  id="menu4"
                  isActive={openingMenuId === "menu4"}
                  icon={<Gear size="1.3rem" className="ml-3" />}
                  text={"تنظیمات"}
                  toggleMenu={toggleMenu}
                >
                  <li>
                    <a href="#">
                      <button>پراکسی</button>
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <button>SMTP</button>
                    </a>
                  </li>
                </MultiMenuItem>
              </ul>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
