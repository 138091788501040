// ============= react ===================
import React from "react";
// ============= styles ===================
import "./Err404.scss";
// ============= images & icons ===================

// ============= in components ===================
import Button from "components/Button/Button";

// ============= ex comonents ===================
import { Link } from "react-router-dom";
// ============= redux ===================

// ===============================================

function Err404() {
  return (
    <div id="Err404_wrapper" className="set_col_middle">
      <h1>خطای 404</h1>
      <h1>متاسفیم ! صفحه‌ی مورد نظر شما یافت نشد.</h1>
      <Button className="w-auto">
        <Link to="/">بازگشت به صفحه اصلی</Link>
      </Button>
    </div>
  );
}

export default Err404;
