// ============= react ===================
import React from "react";
// ============= styles ===================
import "./Table.scss";
// ============= images & icons ===================
import { Pencil, Trash } from "react-bootstrap-icons";
import blacnkProfile from "assets/images/avatar.jpg";
// ============= in components ===================
import MiniLoading from "components/MiniLoading";
// ============= ex comonents ===================
// ================= redux ======================
// ===============================================

const Table = ({ columns, data, loading, editAction, deleteAction }) => {
  return (
    <div className="table_wrapper">
      <div>
        <table>
          <thead>
            <tr>
              {columns.map((c, i) => (
                <th key={i}>{c}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {loading ? (
              <tr>
                <td colSpan={columns.length} className="">
                  <MiniLoading size={70}/>
                </td>
              </tr>
            ) : data && data?.length > 0 ? (
              data?.map((item, index) => (
                <tr key={index} id={item.id}>
                  <td>
                    <div className="flex items-center justify-center">
                      <div className="flex-shrink-0 w-10 h-10">
                        <img
                          className="w-full h-full rounded-full"
                          src={item.image ? item.image : blacnkProfile}
                          // https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2.2&w=160&h=160&q=80
                          alt="profile"
                        />
                      </div>
                      <div className="mr-3">
                        <p className="text-gray-900 whitespace-nowrap">
                          {`${item.name} ${item.family}`}
                        </p>
                      </div>
                    </div>
                  </td>
                  <td>
                    <p className="text-gray-900 whitespace-nowrap">
                      {item.codeMelli}
                    </p>
                  </td>
                  <td>
                    <p className="text-gray-900 whitespace-nowrap">
                      {item.mobile}
                    </p>
                  </td>
                  <td>
                    <p className="text-gray-900 whitespace-nowrap">
                      {item.phone ? item.phone : "---"}
                    </p>
                  </td>
                  <td>
                    <span className="relative inline-block px-3 py-1 font-semibold text-green-900 leading-tight">
                      <span
                        aria-hidden
                        className="absolute inset-0 bg-green-200 opacity-50 rounded-full"
                      ></span>
                      <span className="relative">فعال</span>
                    </span>
                  </td>
                  <td>
                    <div className="actions">
                      <button
                        className="edit_btn"
                        onClick={() => {
                          editAction(item);
                        }}
                      >
                        <Pencil />
                        <span>ویرایش</span>
                      </button>
                      <button
                        className="delete_btn"
                        onClick={() => {
                          deleteAction(item);
                        }}
                      >
                        <Trash />
                        <span>حذف</span>
                      </button>
                    </div>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={columns.length} className="text-center font-bold">
                  لیست خالی میباشد
                </td>
              </tr>
            )}
          </tbody>
        </table>
        {/* <div className="px-5 py-5 bg-white border-t flex flex-col xs:flex-row items-center xs:justify-between">
          <div className="inline-flex mt-2 xs:mt-0">
            <button className="m-1 text-sm text-indigo-50 transition duration-150 hover:bg-main-300 bg-main-400 font-semibold py-2 px-4 rounded-r">
              قبلی
            </button>
            <button className="m-1 text-sm text-indigo-50 transition duration-150 hover:bg-main-300 bg-main-400 font-semibold py-2 px-4 rounded-l">
              بعدی
            </button>
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default Table;
