//============== React & Next ================
import React, { useId } from "react";
import "./FloatingInput.scss";
//============== images & icons ==============
//============== in Components ===============
//============== ex Components ===============
//================= redux ====================
//============================================
function FloatingInput(props) {
  const _id = useId();
  const { error, label, wrapperClassNames, className, ...otherProps } = props;
  return (
    <div
      className={`FloatingInput_wrapper ${
        wrapperClassNames ? wrapperClassNames : ""
      }`}
    >
      <input
        id={_id}
        {...otherProps}
        autoComplete="off"
        className={`${error ? "invalid" : ""} ${className ? className : ""}`}
      />
      <label htmlFor={_id} className={otherProps.value ? "filled" : ""}>
        {label}
      </label>
      {error && <p className="error">{error}</p>}
    </div>
  );
}

export default FloatingInput;
