//================== React ===================
// import Swal from "sweetalert2";
// Swal.fire({
//   icon: "error",
//   title: "خطا",
//   text: "نام کابری یا رمز عبور اشتباه است.",
// })
//================= assets ===================
//============== in components ===============
import * as api from "apis/Student";
import { queryClient } from "App";
//============== ex components ===============
import { useQuery, useMutation } from "react-query";
import toast from "react-hot-toast";
//================= redux ====================
//============================================
const useAllStudents = (values) => {
  return useQuery("students", () => api.getAllStudents(values));
};
const useAddStudent = () => {
  return useMutation(api.addStudent, {
    onSuccess: (response, params) => {
      if (response.status) {
        const lastData = queryClient.getQueryData("students");
        lastData.data.push(response.item);
        params.onFinish();
        toast.success("دانـش‌ آموز جدید با موفقیت اضافه شد");
      } else {
        toast.error("خطا در ذخیره سازی اطلاعات");
      }
    },
  });
};
const useUpdateStudent = () => {
  return useMutation(api.updateStudent, {
    onSuccess: (response, params) => {
      if (response.status) {
        const lastData = queryClient.getQueryData("students");
        const newData = lastData.data.map((stud) => {
          if (stud.sStudentId === response.item.sStudentId) {
            return {
              ...stud,
              name: response.item.name,
              family: response.item.family,
              image: response.item.image,
              codeMelli: response.item.codeMelli,
              mobile: response.item.mobile,
              phone: response.item.phone,
            };
          }
          return stud;
        });
        queryClient.setQueryData("students", { ...lastData, data: newData });
        params.onFinish();
        toast.success("دانـش‌ آموز با موفقیت ویرایش شد");
      } else {
        toast.error("خطا در به روزرسانی اطلاعات");
      }
    },
  });
};
const useDeleteStudent = () => {
  return useMutation(api.deleteStudent, {
    onSuccess: (response, deletedStudentId) => {
      if (response.status) {
        const lastData = queryClient.getQueryData("students");
        const newData = lastData.data.filter(
          (item) => item.sStudentId !== deletedStudentId
        );
        queryClient.setQueryData("students", { ...lastData, data: newData });
        toast.success("دانـش‌ آموز با موفقیت حذف گردید");
      } else {
        toast.error("خطا در حذف اطلاعات");
      }
    },
  });
};
export { useAllStudents, useAddStudent, useUpdateStudent, useDeleteStudent };
