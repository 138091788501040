// ============= react ===================
import React, { useState } from "react";
// ============= styles ===================
import "./Login.scss";
// ============= images & icons ==================
import { ArrowLeft, KeyFill, PersonFill } from "react-bootstrap-icons";
// ============= in components ===================
// ============= ex comonents ===================
import { useNavigate } from "react-router-dom";
import validator from "validator";
import Swal from "sweetalert2";
// =================== redux ====================
// ===============================================

function Login() {
  const navigate = useNavigate();
  const initialInputsState = {
    username: "",
    usernameErr: "",
    password: "",
    passwordErr: "",
  };
  const [inputs, setInputs] = useState(initialInputsState);
  const [buttonLoading, setButtonLoading] = useState(false);
  const onChangeHandler = (e) => {
    setInputs({
      ...inputs,
      [e.target.name]: e.target.value,
    });
  };
  const loginHandler = (e) => {
    e.preventDefault();
    setButtonLoading(true);
    let usernameIsEmpty = validator.isEmpty(inputs.username);
    let passwordIsEmpty = validator.isEmpty(inputs.password);
    let isValid = true;
    if (usernameIsEmpty) {
      setInputs((prevState) => ({
        ...prevState,
        usernameErr: "نام کاربری نمیتواند خالی باشد.",
      }));
      isValid = false;
    } else {
      setInputs((prevState) => ({
        ...prevState,
        usernameErr: "",
      }));
    }
    if (passwordIsEmpty) {
      setInputs((prevState) => ({
        ...prevState,
        passwordErr: "رمز عبور نمیتواند خالی باشد.",
      }));
      isValid = false;
    } else {
      setInputs((prevState) => ({
        ...prevState,
        passwordErr: "",
      }));
    }
    setTimeout(() => {
      if (isValid) {
        if (inputs.username === "admin" && inputs.password === "Star@123") {
          navigate("/dashboard");
        } else {
          Swal.fire({
            icon: "error",
            title: "خطا",
            text: "نام کابری یا رمز عبور اشتباه است.",
          });
          setButtonLoading(false);
        }
      } else {
        setButtonLoading(false);
      }
    }, 3000);
  };
  return (
    <div className="login_container">
      <div className="screen">
        <div className="screen__content">
          <form className="login">
            <div className="login__field">
              <PersonFill className="login__icon" />
              <input
                type="text"
                placeholder="نام کاربری"
                name="username"
                value={inputs.username}
                className={`login__input ${inputs.usernameErr ? "invalid_input" : ""}`}
                onChange={onChangeHandler}
                autoComplete="new-password"
              />
              <p className="text-red-500 text-xs mt-1">{inputs.usernameErr}</p>
            </div>
            <div className="login__field">
              <i className=" fas fa-lock"></i>
              <KeyFill className="login__icon" />
              <input
                type="password"
                placeholder="رمز عبور"
                name="password"
                value={inputs.password}
                className={`login__input ${inputs.passwordErr ? "invalid_input" : ""}`}
                onChange={onChangeHandler}
                autoComplete="new-password"
              />
              <p className="text-red-500 text-xs mt-1">{inputs.passwordErr}</p>
            </div>
            <button className="login__submit" onClick={loginHandler} disabled={buttonLoading}>
              {buttonLoading ?
                <div className="button_loading">
                  <div className="line"></div>
                  <div className="line"></div>
                  <div className="line"></div>
                </div>
                : <>
                  <span className="button__text">ورود به سامانه</span>
                  <ArrowLeft className="button__icon" />
                </>
              }
            </button>
          </form>
          {/* <div className="social-login">
            <h3>log in via</h3>
            <div className="social-icons">
              <a href="#" className="social-login__icon fab fa-instagram"></a>
              <a href="#" className="social-login__icon fab fa-facebook"></a>
              <a href="#" className="social-login__icon fab fa-twitter"></a>
            </div>
          </div> */}
        </div>
        <div className="screen__background">
          <span
            className="screen__background__shape screen__background__shape4"
          ></span>
          <span
            className="screen__background__shape screen__background__shape3"
          ></span>
          <span
            className="screen__background__shape screen__background__shape2"
          ></span>
          <span
            className="screen__background__shape screen__background__shape1"
          ></span>
        </div>
      </div>
    </div>
  );
}

export default Login;
