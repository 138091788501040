import { createSlice } from '@reduxjs/toolkit'

const loadingSlice = createSlice({
    name: 'modalLoading',
    initialState: { show: false },
    reducers: {
        toggleLoading: (state, { payload }) => {
            state.show = payload;
        },
    }
});
export const { toggleLoading } = loadingSlice.actions;

export default loadingSlice.reducer;