// ============= react ===================
import React from "react";
import { Link } from "react-router-dom";
// ============= styles ===================
// ============= images & icons ===================
// ============= in components ===================
// ============= ex comonents ===================
// =================== redux ====================

function SingleMenuItem({ isActive, icon, text, href = "#" }) {
  return (
    <li className={`parent_item ${isActive ? "is-open" : ""}`}>
      <Link className="list-item" to={href}>
        {icon}
        <span>{text}</span>
      </Link>
    </li>
  );
}

export default SingleMenuItem;
