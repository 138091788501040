import axios from "axios";
import Swal from "sweetalert2";
import { BASEURL } from "./Constants";
const client = axios.create({
  baseURL: BASEURL,
});
// let storedData = JSON.parse(localStorage.getItem("_data"));
// client.defaults.headers.common["Authorization"] = storedData
//   ? "Bearer " + storedData.token
//   : "";
client.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    console.error(error);
    if (error.response) {
      if (error.response.status === 401) {
        Swal.fire({
          icon: "error",
          title: "خطا",
          text: "توکن احراز هویت شما منقضی شده است\nلطفا دوباره وراد شوید.",
        });
        setTimeout(() => {
          window.location.href = "/";
        }, 4000);
      }
    }
    return Promise.reject(error);
  }
);
export default client;
