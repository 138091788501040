// ============= react ===================
import React from "react";
// ============= styles ===================
import "./Loading.scss";
// ============= images & icons ===================
// ============= in components ===================
// ============= ex comonents ===================
// ============= redux ===================
import { useSelector } from "react-redux";
// ===============================================

function Loading() {
  const { show } = useSelector((state) => state.loadingState);
  return (
    <div
      id="loading_wrapper"
      className={`set_col_middle ${show ? "" : "hidden"}`}
    >
      <div className="spinner-box">
        <div className="blue-orbit leo"></div>
        <div className="green-orbit leo"></div>
        <div className="red-orbit leo"></div>
        <div className="white-orbit w1 leo"></div>
        <div className="white-orbit w2 leo"></div>
        <div className="white-orbit w3 leo"></div>
      </div>
      <h1 className="text-2xl text-white">در حال بارگذاری اطلاعات ...</h1>
    </div>
  );
}

export default Loading;
