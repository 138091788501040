import React from "react";
import { Navigate, Outlet } from "react-router-dom";
const useAuth = () => {
  const user = JSON.parse(localStorage.getItem("_data"));
  return user && user.token;
}
function ProtectedRoute() {
  const isAuth = useAuth();
  return true ? (
    <Outlet />
  ) : (
    <Navigate to="/" />
  );
}

export default ProtectedRoute;
