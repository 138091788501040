import Swal from "sweetalert2";
import client from "utils/AxiosInterceptor";
import {IMAGEURLS} from "utils/Constants";

const upload = (e,onStart, onFinish) => {
  let size = (e.target.files[0].size / 1024).toFixed(2);
  let fileName = e.target.files[0].name;
  if (size > 7000) {
    Swal.fire({
      icon: "error",
      title: "خطا",
      text: "حداکثر حجم مجاز 7 مگابایت است",
    });
  } else if (fileName.includes(".exe")) {
    Swal.fire({
      icon: "error",
      title: "خطا",
      text: "فایل‌های اجرایی را نمیتوان آپلود کرد",
    });
  } else {
    onStart();
    let reader = new FileReader();
    reader.onloadend = () => {
      client
        .post("/Setting/Upload", {
          file: reader.result,
          name: fileName,
        })
        .then((response) => {
          if (response.data.status) {
            onFinish(IMAGEURLS + response.data.message);
          }
        });
    };
    reader.readAsDataURL(e.target.files[0]);
  }
};

export { upload };
