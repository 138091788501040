import client from "utils/AxiosInterceptor";
const getAllStudents = async (values) => {
  const { data } = await client.post(`/Student/List`, values);
  return data.items;
};
const addStudent = async (params) => {
  let newStudent = params.values;
  const { data } = await client.post(`/Student/Edit`, newStudent);
  return data;
};
const updateStudent = async (params) => {
  let updatedStudent = params.values;
  const { data } = await client.post(`/Student/Edit`, updatedStudent);
  return data;
};
const deleteStudent = async (deletedStudentId) => {
  const { data } = await client.post(`/Student/Delete`, {
    id: deletedStudentId,
  });
  return data;
};
export { getAllStudents, addStudent, updateStudent, deleteStudent };
