import "styles/global.scss";
import { QueryClient, QueryClientProvider } from "react-query";
import { Toaster } from "react-hot-toast";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import ProtectedRoute from "routes/ProtectedRoute";
import Login from "pages/Login/Login";
import DashboardHome from "pages/DashboardHome/DashboardHome";
import Students from "pages/Students/Students";
import Err404 from "pages/Err404/Err404";

export const queryClient = new QueryClient();

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <Toaster position="bottom-right" reverseOrder={false} />
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route element={<ProtectedRoute />}>
            <Route path="dashboard" element={<DashboardHome />} />
            <Route path="students" element={<Students />} />
          </Route>
          <Route path="*" element={<Err404 />} />
        </Routes>
      </BrowserRouter>
    </QueryClientProvider>
  );
}

export default App;
