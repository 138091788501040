// ============= react ===================
import React, { useState, useEffect, useRef } from "react";
import { useOnClickOutside } from "hooks/custom/useOnClickOutside";
// ============= styles ===================
import "./DashboardLayout.scss";
// ============= images & icons ===================
import {
  BoxArrowLeft,
  List,
  MoonStarsFill,
  SunFill,
} from "react-bootstrap-icons";
// ============= in components ===================
import { THEMES } from "utils/Constants";
import Loading from "components/Loading/Loading";
import Sidebar from "./Sidebar/Sidebar";
// ============= ex comonents ===================
import { useNavigate } from "react-router-dom";
// =================== redux ====================
// ===============================================

function DashboardLayout({ children }) {
  const sideBarRef = useRef();
  const navigate = useNavigate();
  const [openSideBar, setOpenSideBar] = useState(false);
  const [theme, setTheme] = useState(THEMES.LIGHT);
  // const toggleTheme = () => {
  //   if (theme === THEMES.DARK) {
  //     setTheme(THEMES.LIGHT);
  //     localStorage.setItem("data-theme", THEMES.LIGHT);
  //   } else {
  //     setTheme(THEMES.DARK);
  //     localStorage.removeItem("data-theme");
  //   }
  // };
  const logout = () => {
    navigate("/");
  };
  useOnClickOutside(sideBarRef, () => {
    if (window.innerWidth < 1024 && openSideBar) {
      setOpenSideBar(false);
    }
  });

  // useEffect(() => {
  //   let theme = localStorage.getItem("data-theme");
  //   if (theme) {
  //     setTheme(theme);
  //   }
  // }, []);

  return (
    <div className="dashboard_wrapper disable-scrollbars">
      <div
        data-theme={theme === THEMES.DARK ? "dark" : "light"}
        data-direction="rtl"
        data-collapsed={openSideBar}
        data-left-sidebar={
          theme === THEMES.DARK ? "bg-gray-800" : "bg-gray-100"
        }
        data-top-navigation={theme === THEMES.DARK ? "bg-gray-900" : "white"}
      >
        <div className="navbar navbar-1">
          <div className="navbar-inner w-full flex items-center justify-between">
            <div>
              <button
                className="mx-4 lg:hidden"
                onClick={() => {
                  setOpenSideBar(!openSideBar);
                }}
              >
                <List size="2rem" />
              </button>
            </div>
            <div>
              <button
                className="mx-4 float-left set_row_middle"
                onClick={logout}
              >
                خروج
                <BoxArrowLeft size="1.3rem" className="mr-1" />
              </button>
              {/* <button className="mx-4 float-left" onClick={toggleTheme}>
                {theme === THEMES.DARK ? (
                  <SunFill size="1.3rem" />
                ) : (
                  <MoonStarsFill size="1.3rem" />
                )}
              </button> */}
            </div>
          </div>
        </div>
        <Sidebar sideBarRef={sideBarRef} />
        <div className="main w-full">
          {/* <Loading /> */}
          <div className="py-20 px-4 min-h-screen">{children}</div>
        </div>
      </div>
    </div>
  );
}

export default DashboardLayout;
