// ============= react ===================
import React, { useState, useRef } from "react";
// ============= styles ===================
import "./Students.scss";
// ============= images & icons ===================
import blacnkProfile from "assets/images/avatar.jpg";
import { Upload } from "react-bootstrap-icons";
// ============= in components ===================
import DashboardLayout from "components/DashboardLayout/DashboardLayout";
import Button, { ButtonTypes } from "components/Button/Button";
import FloatingInput from "components/FloatingInput/FloatingInput";
import Table from "components/Table/Table";
import {
  useAddStudent,
  useAllStudents,
  useDeleteStudent,
  useUpdateStudent,
} from "hooks/Student";
import Modal from "components/Modal/Modal";
// ============= ex comonents ===================
import validator from "validator";
import Swal from "sweetalert2";
import * as settingApi from "apis/Setting";
// import Webcam from "react-webcam";
import IRCheck from "ircheck";
// ================= redux ===================
// ===============================================

function Students() {
  const imageSelectorRef = useRef(null);
  const columns = [
    "نام و نام خانوادگی",
    "کدملی",
    "موبایل",
    "تلفن ثابت",
    "وضعیت",
    "عملیات",
  ];
  const [showModal, setShowModal] = useState(false);
  const [uploadImageLoading, setUploadImageLoading] = useState(false);
  // const [showWebCam, setShowWebCam] = useState(false);
  // const videoConstraints = {
  //   width: 1280,
  //   height: 720,
  //   facingMode: "forward", //user/selfie/forward
  // };

  let initialStudentInputs = {
    sStudentId: "",
    name: "",
    family: "",
    image: "",
    codeMelli: "",
    mobile: "",
    phone: "",
  };
  const [studentInputs, setstudentInputs] = useState(initialStudentInputs);
  const [studentInputsErr, setstudentInputsErr] =
    useState(initialStudentInputs);
  const studentInputsOnChangeHandler = (e) => {
    setstudentInputs({
      ...studentInputs,
      [e.target.name]: e.target.value,
    });
  };

  const {
    data: studentsList,
    isLoading: studentsListLoading,
    isError: studentsListIsError,
    refetch: refetchStudentsList,
  } = useAllStudents({
    page: 1,
    take: 100,
    orderBy: "",
    filter: "",
  });
  const { mutate: addStudentApi, isLoading: addStudentApiLoading } =
    useAddStudent();
  const { mutate: updateStudentApi, isLoading: updateStudentApiLoading } =
    useUpdateStudent();
  const { mutate: deleteStudentApi, isLoading: deleteStudentApiLoading } =
    useDeleteStudent();
  const studentInputsValidation = () => {
    let isValid = true;
    if (validator.isEmpty(studentInputs.name)) {
      setstudentInputsErr((prevState) => ({
        ...prevState,
        name: "نام نمیتواند خالی باشد",
      }));
      isValid = false;
    } else {
      setstudentInputsErr((prevState) => ({ ...prevState, name: "" }));
    }
    if (validator.isEmpty(studentInputs.family)) {
      setstudentInputsErr((prevState) => ({
        ...prevState,
        family: "نام خانوادگی نمیتواند خالی باشد",
      }));
      isValid = false;
    } else {
      setstudentInputsErr((prevState) => ({ ...prevState, family: "" }));
    }
    if (
      !validator.isEmpty(studentInputs.codeMelli) &&
      !IRCheck.National.isNationalCodeValid(studentInputs.codeMelli)
    ) {
      setstudentInputsErr((prevState) => ({
        ...prevState,
        codeMelli: "کدملی معتبر وارد کنید",
      }));
      isValid = false;
    } else {
      setstudentInputsErr((prevState) => ({ ...prevState, codeMelli: "" }));
    }
    if (
      !validator.isEmpty(studentInputs.mobile) &&
      studentInputs.mobile.length !== 11
    ) {
      setstudentInputsErr((prevState) => ({
        ...prevState,
        mobile: "شماره موبایل معتبر وارد کنید",
      }));
      isValid = false;
    } else {
      setstudentInputsErr((prevState) => ({ ...prevState, mobile: "" }));
    }
    return isValid;
  };
  const iWantToAddStudent = () => {
    setstudentInputs(initialStudentInputs);
    setstudentInputsErr(initialStudentInputs);
    setShowModal(true);
  };
  const addStudent = () => {
    if (studentInputsValidation()) {
      let inps = { ...studentInputs };
      delete inps.sStudentId;
      addStudentApi({
        values: inps,
        onFinish: () => {
          setShowModal(false);
        },
      });
    }
  };
  const iWantToEditStudent = (student) => {
    setstudentInputs(student);
    setstudentInputsErr(initialStudentInputs);
    setShowModal(true);
  };
  const updateStudent = () => {
    if (studentInputsValidation()) {
      updateStudentApi({
        values: studentInputs,
        onFinish: () => {
          setShowModal(false);
        },
      });
    }
  };
  const iWantToDeleteStudent = (student) => {
    Swal.fire({
      title: "توجه",
      text: `آیا از حذف ${student.name} ${student.family} مطمئن هستید؟`,
      icon: "warning",
      showCancelButton: true,
      cancelButtonText: "خیر",
      cancelButtonColor: "#df4759",
      confirmButtonColor: "#776bcc",
      confirmButtonText: "بله",
    }).then((result) => {
      if (result.isConfirmed) {
        deleteStudentApi(student.sStudentId);
      }
    });
  };
  const uploadImage = (e) => {
    const onStartUpload = () => {
      setUploadImageLoading(true);
    };
    const onFinishUpload = (imageUrl) => {
      setstudentInputs({
        ...studentInputs,
        image: imageUrl,
      });
      setUploadImageLoading(false);
    };
    settingApi.upload(e, onStartUpload, onFinishUpload);
  };

  return (
    <DashboardLayout>
      <div className="Students_wrapper">
        <div className="rounded-md w-full">
          <div className="flex items-center justify-center sm:justify-between mb-3 flex-wrap">
            <div>
              <h2 className="text-gray-600 font-semibold">دانـش‌ آموزان</h2>
              <span className="text-xs">لیست همه دانـش‌ آموزان</span>
            </div>
            <div className="flex items-center justify-between">
              {/* <div className="flex bg-gray-50 items-center p-2 rounded-md">
                <span>جـــســت و جـــو</span>
                <Search />
                <input className="bg-gray-50 ml-1 block border border-gray-300" type="text" name="" id="" placeholder="..." />
              </div> */}
              <div className="flex justify-center items-center">
                <Button type={ButtonTypes.OUTLINE}>گزارش گیری</Button>
                <Button type={ButtonTypes.FILL} onClick={iWantToAddStudent}>
                  افزودن دانش آموز جدید
                </Button>
              </div>
            </div>
          </div>
          <Table
            data={studentsList?.data}
            columns={columns}
            loading={studentsListLoading || deleteStudentApiLoading}
            editAction={iWantToEditStudent}
            deleteAction={iWantToDeleteStudent}
          />
        </div>
      </div>
      <Modal
        show={showModal}
        setShow={setShowModal}
        loading={
          addStudentApiLoading || updateStudentApiLoading || uploadImageLoading
        }
        title={
          studentInputs.sStudentId
            ? "ویرایش دانـش‌ آموز"
            : "افزودن دانـش‌ آموز جـدید"
        }
      >
        {/* {showWebCam ? (
          <Webcam
            audio={false}
            height={720}
            screenshotFormat="image/jpeg"
            width={1280}
            videoConstraints={videoConstraints}
          >
            {({ getScreenshot }) => (
              <div className="flex items-center justify-center">
                <Button
                  type={ButtonTypes.FILL}
                  onClick={() => {
                    const imageSrc = getScreenshot();
                    console.log(imageSrc);
                    setShowWebCam(false);
                  }}
                >
                  ثبت تصویر
                </Button>
              </div>
            )}
          </Webcam>
        ) : (
          <> */}
        <div className="flex flex-wrap items-center justify-center lg:justify-start pb-5">
          <div className="w-full flex items-center justify-center mb-5">
            <div
              className="relative w-36 h-36 rounded-full overflow-hidden border-2 border-gray-300 p-1 cursor-pointer"
              onClick={() => {
                imageSelectorRef.current.click();
              }}
            >
              <input
                type="file"
                name="image"
                className="!hidden"
                ref={imageSelectorRef}
                onChange={(e) => {
                  uploadImage(e);
                }}
              />
              <div className="opacity-0 hover:opacity-100 transition-all flex items-center justify-center absolute top-0 left-0 w-full h-full bg-black bg-opacity-30 text-3xl text-white">
                <Upload />
              </div>
              <img
                className="w-full h-full rounded-full"
                src={studentInputs.image ? studentInputs.image : blacnkProfile}
                alt="profile"
              />
            </div>
          </div>
          <FloatingInput
            label="نام"
            name="name"
            // placeholder=""
            // wrapperClassNames=""
            value={studentInputs.name}
            error={studentInputsErr.name}
            onChange={studentInputsOnChangeHandler}
            required={true}
          />
          <FloatingInput
            label="نام خانوادگی"
            name="family"
            value={studentInputs.family}
            error={studentInputsErr.family}
            onChange={studentInputsOnChangeHandler}
            required={true}
          />
          <FloatingInput
            label="کد ملی"
            type="number"
            name="codeMelli"
            value={studentInputs.codeMelli}
            error={studentInputsErr.codeMelli}
            onChange={studentInputsOnChangeHandler}
            required={true}
          />
          <FloatingInput
            label="شماره موبایل"
            type="number"
            name="mobile"
            value={studentInputs.mobile}
            error={studentInputsErr.mobile}
            onChange={studentInputsOnChangeHandler}
            required={true}
          />
          <FloatingInput
            label="تلفن ثابت"
            type="number"
            name="phone"
            value={studentInputs.phone}
            error={studentInputsErr.phone}
            onChange={studentInputsOnChangeHandler}
            required={true}
          />
        </div>
        <hr className="my-2" />
        <div className="flex items-center justify-end">
          <Button
            type={ButtonTypes.OUTLINE}
            onClick={() => {
              setShowModal(false);
            }}
          >
            انصراف
          </Button>
          {studentInputs.sStudentId ? (
            <Button type={ButtonTypes.FILL} onClick={updateStudent}>
              به‌روز رسانی اطلاعات
            </Button>
          ) : (
            <Button type={ButtonTypes.FILL} onClick={addStudent}>
              ثبت اطلاعات
            </Button>
          )}
        </div>
        {/* </>
        )} */}
      </Modal>
    </DashboardLayout>
  );
}

export default Students;
