// ============= react ===================
import React, { useState } from "react";
// ============= styles ===================
import "./DashboardHome.scss";
// ============= images & icons ===================
// ============= in components ===================
import DashboardLayout from "components/DashboardLayout/DashboardLayout";
// ============= ex comonents ===================
import ReactApexChart from "react-apexcharts";
// ================= redux ===================
import { ChatDotsFill, CurrencyDollar, ListTask, PeopleFill } from "react-bootstrap-icons";
// ===============================================

function DashboardHome() {
  const [options, setOptions] = useState({
    chart: {
      type: "bar",
      height: 350,
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "55%",
        endingShape: "rounded",
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 2,
      colors: ["transparent"],
    },
    xaxis: {
      categories: [
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
      ],
    },
    yaxis: {
      title: {
        text: "$ (thousands)",
      },
    },
    fill: {
      opacity: 1,
    },
    tooltip: {
      y: {
        formatter: function (val) {
          return "$ " + val + " thousands";
        },
      },
    },
  });
  const [series, setSeries] = useState([
    {
      name: "Net Profit",
      data: [44, 55, 57, 56, 61, 58, 63, 60, 66],
    },
    {
      name: "Revenue",
      data: [76, 85, 101, 98, 87, 105, 91, 114, 94],
    },
    {
      name: "Free Cash Flow",
      data: [35, 41, 36, 26, 45, 48, 52, 53, 41],
    },
  ]);
  const [options1, setOptions1] = useState({
    chart: {
      type: "polarArea",
    },
    stroke: {
      colors: ["#fff"],
    },
    fill: {
      opacity: 0.8,
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
          },
          legend: {
            position: "bottom",
          },
        },
      },
    ],
  });
  const [series1, setSeries1] = useState([14, 23, 21, 17, 15, 10, 12, 17, 21]);

  return (
    <DashboardLayout>
      <div className="dashboardHome_wrapper">
        <div className="flex flex-wrap">
          <div className="w-1/2 md:w-3/12">
            <div className="p-2">
              <div className="dashboard_card border-blue-500 flex flex-col md:flex-row">
                <div className="text-center my-2 md:my-4">
                  <p className="text-sm md:text-base font-bold text-blue-500">درآمد ماهیانه (تومان)</p>
                  <p className="text-base md:text-xl font-bold mt-2 text-gray-800">10,320,000</p>
                </div>
                <div className="text-center my-2 md:my-4">
                  <CurrencyDollar className="text-gray-300 text-2xl md:text-3xl" />
                </div>
              </div>
            </div>
          </div>
          <div className="w-1/2 md:w-3/12">
            <div className="p-2">
              <div className="dashboard_card border-red-400 flex flex-col md:flex-row">
                <div className="text-center my-2 md:my-4">
                  <p className="text-sm md:text-base font-bold text-red-400">تعداد دانـش‌ آموزان</p>
                  <p className="text-base md:text-xl font-bold mt-2 text-gray-800">2500</p>
                </div>
                <div className="text-center my-2 md:my-4">
                  <PeopleFill className="text-gray-300 text-2xl md:text-3xl" />
                </div>
              </div>
            </div>
          </div>
          <div className="w-1/2 md:w-3/12">
            <div className="p-2">
              <div className="dashboard_card border-green-400 flex flex-col md:flex-row">
                <div className="text-center my-2 md:my-4">
                  <p className="text-sm md:text-base font-bold text-green-400">وظایف</p>
                  <p className="text-base md:text-xl font-bold mt-2 text-gray-800">48%</p>
                </div>
                <div className="text-center my-2 md:my-4">
                  <ListTask className="text-gray-300 text-2xl md:text-3xl" />
                </div>
              </div>
            </div>
          </div>
          <div className="w-1/2 md:w-3/12">
            <div className="p-2">
              <div className="dashboard_card border-yellow-400 flex flex-col md:flex-row">
                <div className="text-center my-2 md:my-4">
                  <p className="text-sm md:text-base font-bold text-yellow-400">پیام های دریافتی</p>
                  <p className="text-base md:text-xl font-bold mt-2 text-gray-800">18</p>
                </div>
                <div className="text-center my-2 md:my-4">
                  <ChatDotsFill className="text-gray-300 text-2xl md:text-3xl" />
                </div>
              </div>
            </div>
          </div>
          <div className="w-full md:w-6/12 text-black my-5">
            <ReactApexChart
              options={options}
              series={series}
              type="bar"
              height={350}
            />
          </div>
          <div className="w-full md:w-6/12 text-black my-5">
            <ReactApexChart
              options={options1}
              series={series1}
              type="polarArea"
              height={350}
            />
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
}

export default DashboardHome;
